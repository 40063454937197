blockquote {
  background-color: $tipColor;
  padding: 30px 35px 10px 35px;
  border-left: 5px solid $darkBlue;
  margin: 0;
  margin-bottom: 20px !important;
}

@mixin accordeonHover() {
  background-color: #f5f5f5;
  color: $darkBlue;
}
/* Acordeon styles */
.accordeon {
  position: relative;
  margin-bottom: 1px;
  width: 100%;
  //color: #fff;
  overflow: hidden;
  border-bottom: 1px solid $borderColor;

  img {
    float: left;
    padding-right: 30px;
    padding-top: 7px;
    margin-bottom: 12px;
  }
  &:first-of-type {
    border-top: 1px solid $borderColor;
  }
  &Name {
    margin-bottom: 0;
    padding-bottom: 5px;
    padding-top: 2px;
  }
  &Position {
    color: rgba(0,0,0,0.7);
    font-weight: 400;
  }
}
.accordeon input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.accordeon label {
  position: relative;
  display: block;
  padding: 15px 30px;
  background: $white;
  color: $lightBlue;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  line-height: 1.2;
  cursor: pointer;
  transition: 0.2s background-color, 0.2s color;
  @media (max-width: $sm) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: $xs) {
    font-size: 14px;
    line-height: 20px;
    padding: 9px 9px;
  }
  &:hover {
    @include accordeonHover();
  }
}

.accordeon-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .35s ease-in-out;
  padding-left: 40px;
  padding-right: 40px;


  @media (max-width: $md) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: $xs) {
    padding-left: 0;
    padding-right: 0;
  }

  > *:first-child {
      padding-top: 30px;
  }

  > *:last-child {
    padding-bottom: 20px;
  }

  @media (max-width: $md) {
    > *:first-child {
      padding-top: 20px;
    }

    > *:last-child {
      padding-bottom: 10px;
    }
  }
  @media (max-width: $xs) {
    > *:first-child {
      padding-top: 10px;
    }

    > *:last-child {
      padding-bottom: 0px;
    }
  }


  }
.accordeon-content p {
  //margin-top: 1em;
  //margin-bottom: 1em;
  font-size: 16px;
  line-height: 26px;
  @media (max-width: $md) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: $sm) {
    font-size: 12px;
    line-height: 22px;
  }
}
/* :checked */
.accordeon input:checked ~ .accordeon-content {
  max-height: 5000px;
}
/* Icon */
.accordeon label::after {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 3em;
  height: 3em;
  line-height: 3;
  text-align: center;
  transition: all .35s;

}
.accordeon input[type=checkbox] + label::after {
  font-family: 'gacr-icons';
  content: '\e801';
  color: $lightBlue;

}
.accordeon input[type=checkbox]:checked + label {
  @include accordeonHover();
}
.accordeon input[type=radio] + label::after {
  content: "\25BC";
}
.accordeon input[type=checkbox]:checked + label::after {
  transform: rotate(180deg);
  color: $darkBlue;
}
.accordeon input[type=radio]:checked + label::after {
  transform: rotateX(180deg);
}


ul.tree, ul.tree ul {
  list-style-type: none;
}
ul.tree, ul.tree ul {
  list-style-type: none;
  background: url(vline.png) repeat-y;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

ul.tree ul {
  margin-left: 10px;
}

ul.tree li {
  margin: 0;
  padding: 0 12px;
}

.listSmStripe_content > .attachment-label {
  &:after {
    content: '|';
    //position: relative;
    //left: 10px;
  }
  &:last-of-type:after {
    content: '';
  }
}


@mixin file-icons() {
  background-image: url("../img/icons/attachments/unknown.png");
  &.avi {
    background-image: url("../img/icons/attachments/avi.png");
  }
  &.doc {
    background-image: url("../img/icons/attachments/doc.png");
  }
  &.gif {
    background-image: url("../img/icons/attachments/gif.png");
  }
  &.jpg {
    background-image: url("../img/icons/attachments/jpg.png");
  }
  &.mp3 {
    background-image: url("../img/icons/attachments/mp3.png");
  }
  &.pdf {
    background-image: url("../img/icons/attachments/pdf.png");
  }
  &.png {
    background-image: url("../img/icons/attachments/png.png");
  }
  &.ppt {
    background-image: url("../img/icons/attachments/ppt.png");
  }
  &.rar {
    background-image: url("../img/icons/attachments/rar.png");
  }
  &.xls {
    background-image: url("../img/icons/attachments/xls.png");
  }
}

.download-attachments {
  .download-title {
    margin-top: 0 !important;
  }
}

.gacrAttachments {
  margin-bottom: 20px;
  h3 {
    //text-align: left;
  }
  &Stripe {
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid $borderColor;
    &:first-of-type {
      border-top: 1px solid $borderColor;
    }
    &_img {
      float: left;
      display: block;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;

      @include file-icons();

    }
    &_img.-rounded {
      border-radius: 50%;
    }
    &_content {
      float: left;
      display: block;
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% - 50px);
    }
    .-date {
      font-size: 14px;
      color: rgba(0,0,0,0.7);
      text-align: left !important;
    }
  }
}


.submenuListing {
  ul {
    margin: 0;
    padding: 0;
  }

  .menu {
    > li {
      @include liReset();
      > a {
        font-size: 20px;
        @media screen and (max-width: $sm) { font-size: 16px; }
        font-weight: 600;
        padding: 0;
        color: #000000;
        text-decoration: none;

      }
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sub-menu {
    > li {
      @include liReset();
    }
  }
}

.imageDescription {
  font-style: italic;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 40px;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0,0,0,0.8);
  @media (max-width: $md) {
    padding-top: 0;
  }
  @media (max-width: $sm) {
    padding-top: 0;
    padding-bottom: 10px;
  }

  @media (max-width: $xs) {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    line-height: 16px;
  }
}
