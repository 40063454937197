* {
  font-family: 'Open Sans', sans-serif;
}

html, body {
  color: rgba(0,0,0,0.7);
}

h1 {
  font-size: 50px;
  line-height: 65px;
  color: $darkBlue;
  font-family: FuturaToTBook, sans-serif;
  font-weight: 300;
  margin-top: 60px !important;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: $md) {
    font-size: 40px;
    line-height: 45px;
    margin-top: 40px !important;
  }
  @media (max-width: $sm) {
    font-size: 30px;
    line-height: 35px;
    margin-top: 20px !important;
  }
  @media (max-width: $xs) {
    font-size: 25px;
    line-height: 30px;
  }
}

h2 {
  font-size: 40px;
  line-height: 50px;
  color: $darkBlue;
  font-family: FuturaToTBook, sans-serif;
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: 60px !important;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: $md) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (max-width: $sm) {
    font-size: 25px;
    line-height: 35px;
  }
  @media (max-width: $xs) {
    font-size: 20px;
    line-height: 25px;
  }
}

h3 {
  font-size: 30px;
  line-height: 40px;
  color: $darkBlue;
  font-family: FuturaToTBook, sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 50px !important;
  text-align: left;
  text-transform: uppercase;
  @media (max-width: $md) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: $sm) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: $xs) {
    font-size: 18px;
    line-height: 28px;
  }
}

h4 {
  font-size: 20px;
  line-height: 30px;
  color: $darkBlue;
  font-family: FuturaToTDemi, sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 40px !important;
  @media (max-width: $md) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: $sm) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: $xs) {
    font-size: 12px;
    line-height: 16px;
  }
}

h5 {
  font-size: 16px;
  line-height: 26px;
  color: $darkBlue;
  margin-bottom: 20px;
  @media (max-width: $md) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: $sm) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: $xs) {
    font-size: 14px;
    line-height: 24px;
  }
}

h6 {

}

p {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 18px;
  margin-top: 0;
  color: rgba(0,0,0,0.7);
  @media (max-width: $md) {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 16px;
  }
  @media (max-width: $sm) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 14px;
  }
}

p.-small {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 16px;
  @media (max-width: $md) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 14px;
  }
  @media (max-width: $sm) {
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 12px;
  }
}

a {
  text-decoration: underline;
  color: $lightBlue;
  transition: color 0.2s;
  font-weight: 600;
  word-break: break-word;
  &:hover{
    color: $darkBlue;
  }
}

ul {
  list-style: none;
  font-size: 16px;
  line-height: 26px;
  padding-top: 0;
  margin-top: 0;
  padding-left: 18px;
  @media (max-width: $md) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: $sm) {
    font-size: 12px;
    line-height: 22px;
  }
  li {
    color: rgba(0,0,0,0.7);
    position: relative;
   &:before {
     //content: "\2022"; color: $darkBlue;
     content: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="iso-8859-1"?>
<svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
  <g>
    <rect width="6" height="6" rx="2" fill="%231E4382"/>
  </g>
</svg>
');
     font-size: 20px;
     position: absolute;
     top: -4px;
     margin-left: -18px;
     //margin-left: -19px;
   }
 }
}
ol {
  counter-reset: item;
  padding-left: 18px;
  font-size: 16px;
  line-height: 26px;
  @media (max-width: $md) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: $sm) {
    font-size: 12px;
    line-height: 22px;
  }
  li {
    display: block;
    margin-left: 0;
    &:before {
      content: counter(item) ". ";
      counter-increment: item;
      min-width: 30px;
      display: inline-block;
      margin-left: -18px;
    }
  }
}



ul.-big {
  list-style: none;
  font-size: 16px;
  line-height: 26px;
  padding-top: 0;
  margin-top: 0;
  padding-left: 35px;
  adding-top: 10px;
  @media (max-width: $sm) {
    font-size: 14px;
    line-height: 24px;
  }
  li {
   line-height: 28px;
   margin-bottom: 30px;
   font-size: 18px;
   position: relative;
   &:before {
     content: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="iso-8859-1"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M256,0C114.844,0,0,114.839,0,256s114.844,256,256,256s256-114.839,256-256S397.156,0,256,0z M398.825,269.916    L213.273,392.998c-2.838,1.88-6.045,2.783-9.219,2.783c-5.404,0-10.709-2.621-13.927-7.469c-5.099-7.686-3-18.047,4.686-23.146    L359.381,256L194.813,146.835c-7.686-5.099-9.785-15.46-4.686-23.146c5.121-7.686,15.492-9.773,23.146-4.686l185.551,123.081    c4.664,3.093,7.469,8.317,7.469,13.916C406.294,261.599,403.488,266.823,398.825,269.916z" fill="%231e4382"/>
	</g>
</g>
</svg>');
     font-size: 20px;
     position: absolute;
     margin-left: -35px;
     width: 20px;
     height: 20px;
     top: 3px;
   }
 }
}

.img__wrap {
  background-color: #ccc;
  position: relative;
  overflow: hidden;
  img {
    position:relative;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}

img {
  margin-bottom: 20px;
}

.content {
  @include doContent();
  //border: 1px solid black;
}
.content.-header {
  @media (max-width: $sm) {
    width: 100%;
  }
}

.row {
  width: 100%;
  @include clearFix();
}

section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.-textCenter {
  text-align: center;
}
.-textLeft {
  text-align: left;
}

.-fullwidth {
  display: block;
  width: 100%;
}

.-nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: $sm) {
    white-space: normal;
  }
}


input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
select,
textarea {
  background-color: #F5F5F5;
  border: none;
  border-bottom: 1px solid $lightBlue;
  border-radius: 2px;
  //padding: 3px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 20px;
  //border-top: 1px solid black;
  &:focus {
    border-bottom: 1px solid $darkBlue;
    outline: none;
  }
}

textarea {
  padding-top: 15px;
  padding-bottom: 15px;
}

input,select {
  height: 49px;
}

select {
  -webkit-appearance: none;
  position: relative;
}

select {
  padding-right: 40px;
}
select {
  background-size: 20px 20px;
  background-image: url("../img/icons/baseline-arrow_drop_down.svg");
  background-repeat: no-repeat;
  background-position: right 13px center;
}



address {
  margin-bottom: 20px;
}

.widget {
  margin: 0 0 1.5em;
  /* Make sure select elements fit in widgets. */
}

.widget select {
  max-width: 100%;
}

.sticky {
  display: block;
}

.hentry {
  margin: 0 0 1.5em;
}

.updated:not(.published) {
  display: none;
}

/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  /* Theme Footer (when set to scrolling) */
  display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}


/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}


