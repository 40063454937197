/*
 * CUSTOM FONT EXAMPLE
 */

/*@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Regular-webfont.eot);
  src: url(custom/DroidSerif-Regular-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Regular-webfont.woff2) format('woff2'), url(custom/DroidSerif-Regular-webfont.woff) format('woff'), url(custom/DroidSerif-Regular-webfont.ttf) format('truetype'), url(custom/DroidSerif-Regular-webfont.svg#DroidSerif-Regular-webfont) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Italic-webfont.eot);
  src: url(custom/DroidSerif-Italic-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Italic-webfont.woff2) format('woff2'), url(custom/DroidSerif-Italic-webfont.woff) format('woff'), url(custom/DroidSerif-Italic-webfont.ttf) format('truetype'), url(custom/DroidSerif-Italic-webfont.svg#DroidSerif-Italic-webfont) format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Bold-webfont.eot);
  src: url(custom/DroidSerif-Bold-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Bold-webfont.woff2) format('woff2'), url(custom/DroidSerif-Bold-webfont.woff) format('woff'), url(custom/DroidSerif-Bold-webfont.ttf) format('truetype'), url(custom/DroidSerif-Bold-webfont.svg#DroidSerif-Bold-webfont) format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-BoldItalic-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-BoldItalic-webfont.woff2) format('woff2'), url(custom/DroidSerif-BoldItalic-webfont.woff) format('woff'), url(custom/DroidSerif-BoldItalic-webfont.ttf) format('truetype'), url(custom/DroidSerif-BoldItalic-webfont.svg#DroidSerif-BoldItalic-webfont) format('svg');
  font-weight: bold;
  font-style: italic;
}
*/


@font-face {
  font-family: 'FuturaToTBook';
  src: url('fonts/custom/Futura-T-OT-Book.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaToTDemi';
  src: url('fonts/custom/Futura-T-OT-Demi.woff');
  font-weight: normal;
  font-style: normal;
}
