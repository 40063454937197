.gacrIconWrapper {
  display: flex;
}
.gacrIcon.-big {
  font-size: 28px;
  position: relative;
  top: 5px;
}

.gacrIcon {
  color: $darkBlue;
}

.gacrIcon.-row {
  font-size: 28px;
  position: relative;
  top: -6px;
  padding-right: 10px;
  @media (max-width: $sm) {
    top: -9px;
  }
  @media (max-width: $xs) {
    top: -9px;
  }
}
