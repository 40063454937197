.carousel {
  height: 510px;
  position: relative;
  @media (max-width: $md) {
    height: 420px;
  }
  @media (max-width: $sm) {
    height: 320px;
    padding-bottom: 60px;
    br {
      content: ' ';
    }
  }

  &Slide, &Hard {
    position: absolute;
    background-color: #ffffff;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    height: inherit;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50%;
    z-index: 0;
    transition: 1s opacity, 1s background-color;
    @media (max-width: $xs) {
      background: none !important;
      text-align: center;
    }
    &:before {
      position: absolute;
      height: 1000px;
    }
    &.-visible {
      //opacity: 1 !important;
      z-index: 10 !important;
    }
  }
  &Hard {
    position: relative;
    padding-bottom: 60px;
    @media (max-width: $xs) {
      padding-bottom: 0;
    }
  }
  &Content {
    font-weight: 300;
    padding-top: 20px;
    width: 57%;
    text-align: left;
    @media (max-width: $md) { width: 60%; }
    @media (max-width: $xs) { text-align: center; width: 100%; }
    h1.carouselHeader {
      margin: 0;
      line-height: 60px;
      text-align: left;
      @media (max-width: $md) { line-height: 50px; }
      @media (max-width: $sm) { line-height: 40px; }
      @media (max-width: $xs) { text-align: center; }
    }
    .btn {
      margin-top: 10px;
    }
  }
  &ContentHard, &ContentHardAside {
    padding-top: 20px;
    width: 68%;
    text-align: left;
    float: left;
    @media (max-width: $md) { width: 68%; }
    @media (max-width: $sm) { width: 60%; }
    @media (max-width: $xs) { text-align: center; width: 100%; }
    h1.carouselHeader {
      margin: 0;
      line-height: 60px;
      text-align: left;
      @media (max-width: $md) { line-height: 50px; }
      @media (max-width: $sm) { line-height: 40px; }
      @media (max-width: $xs) { text-align: center; }
    }
    .btn {
      margin-top: 10px;
    }
  }

  &ContentHard {
    font-weight: 300;
    h1 {
      @media (max-width: $sm) {
        font-size: 25px;
        line-height: 30px;
      }
    }
    br {
      content: ' ';
    }
    p {
      font-size: 16px;
    }
    ul li { font-size: 14px; }
  }

  &ContentHardAside {
    padding-top: 80px;
    width: 32%;
    float: left;
    padding-left: 20px;
    @media (max-width: $md) { padding-top: 60px; }
    @media (max-width: $sm) { padding-top: 40px; }

    @media (max-width: $sm) {
      width: 40%;
      padding-left: 10px;
    }
    @media (max-width: $xs) { width: 100%; padding-left: 0; }
  }

  &__circle {
    //margin-top: 30px;
    display: inline-block;
    //width: 100%;
    position: absolute;
    bottom: 50px;
    z-index: 15;

    @media (max-width: $xs) {
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
    &Item {
      display: inline-block;
      float: left;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid $lightBlue;
      margin-left: 5px;
      margin-right: 5px;
      cursor: pointer;
      @media (max-width: $xs) {
      }
    }
    &Item.-full {
      background-color: $lightBlue;
    }
  }
}

.carouselBoxSmall {
  background-color: #F5F5F5;
  padding: 30px 20px;
  border-radius: 2px;
  box-shadow: 0 20px 20px rgba(0,0,0,0.1);
  @media (max-width: $sm) {
    padding: 15px 15px;
  }
  h5 {
    margin-top: 0;
    @media (max-width: $sm) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  ol {
    font-size: 14px;
    line-height: 24px;
    padding-left: 25px;

    @media (max-width: $sm) {
      font-size: 12px;
      line-height: 20px;
    }

    li {
      color: rgba(0,0,0,0.7);
      margin-bottom: 10px;
      @media (max-width: $sm) {
        margin-bottom: 7px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    li:before {
      min-width: 25px;
      color: rgba(0,0,0,0.7);
      margin-left: -25px;
    }
  }
}