$searchWidth: 258px;
$searchWidthMd: 238px;

header {
  ul {
    padding-left: 0;
  }
  background-color: $darkBlue;
  padding-top: 16px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  position: relative;
  z-index: 99;
  @media screen and (max-width: $xs-header) { padding-top: 13px; }
    .headerLogo {
      display: block;
      height: 60px;
      float: left;
      width: auto !important;
      @media screen and (max-width: $sm) {
        height: 67px;
        padding-left: 10px;
      }
      @media screen and (max-width: $xs-header) {
        height: 30px;
        margin-bottom: 5px;
      }
  }

  /* topMenu main menu */
  .topMenu {
    float: right;
    @media screen and (max-width: $sm) { padding-right: 10px; }
    @media screen and (max-width: $xs-header) { display: none; }
    > li {
      float: left;
      @include liReset();
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      line-height: 40px;
      z-index: 100;
      &:after {
        content: '|';
        position: relative;
        left: 10px;
        color: $white;
      }
      &:last-child:after {
        content: '';
      }

      > a {
        color: rgba(255,255,255,0.9);
        font-size: 13px;
        font-weight: 300;
        @media screen and (max-width: $sm) {
          font-size: 11px;
        }
        text-decoration: none;
        //padding-left: 10px;
        &:before {
          position: relative;
          content: "|";
          right: 6px;
        }
        &:first-child:before {
          content: "";
        }
        &:first-child {
          padding-left: 0;
        }
      }
      &:hover > ul {
        display: block !important;
      }
    }
    > li.menu-item-has-children {
      &:hover > a:before {
        position: absolute;
        content: " ";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent #000000 transparent;
        bottom: -1px;
        left: calc(50% - 8px);
        right: 0;
        @media screen and (max-width: $xs-header) { display: none; }
      }
      > a:after {
        font-size: 9px;
        position: relative;
        top: -2px;
        padding-left: 4px;
        content: '\25be';
      }
    }

  }

  /* topMenu sub menu */
  .topMenu  > li > ul {
    position: absolute;
    top: 100%;
    background-color: #000000;
    padding: 15px 20px;
    border-radius: 2px;
    z-index: 999;
    display: none;
    right: 0;
    min-width: 165px;
    > li {
      @include liReset();
      position: static;
      > a {
        color: $white;
        font-size: 13px;
        text-decoration: none;
      }
    }
  }



  .headerFlex {
    display: flex;
    margin-top: 10px;
  }

  @keyframes disop {
    0%   { opacity: 0; visibility: visible; }
    10%   { opacity: 0; visibility: visible; }
    100% { opacity: 1; visibility: visible; }
  }


  .mainMenu {
    //position: absolute;
    padding-left: 0;
    width: calc(100% - #{$searchWidth});
    margin-bottom: 0;
    display: block;
    @media screen and (max-width: $md) { width: calc(100% - #{$searchWidthMd}); }
    @media screen and (max-width: $sm) { width: 100%; padding-left: 10px; }
    @media screen and (max-width: $xs-header) {
      display: none;
      position: absolute;
      background-color: #ffffff;
      padding-left: 0;
      right: 0;
      left: 0;
    }
    > li {
      display: inline-block;
      padding-right: 30px;
      position: static;
      &:last-child {
        padding-right: 0;
      }
      @media screen and (max-width: $md) { padding-right: 20px; }
      @media screen and (max-width: $sm) { padding-right: 10px; }
        &:before {
        display: none;
      }
      @media screen and (max-width: $xs-header) {
        color: #000000;
        display: block;
        background-color: #ffffff;
        width: 100%;
        padding-left: 20px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.1);
      }
      &:hover > ul {
        //display: block;
        animation: disop 0.2s forwards;
        animation-delay: 0.2s;
        @media screen and (max-width: $xs-header) { display: none; }
      }
      > a {
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        @media screen and (max-width: $sm) {
          font-size: 12px;
          padding-bottom: 17px;
        }
        @media screen and (max-width: $xs-header) {
          color: #000000;
        }
        position: relative;
        z-index: 20;
        padding-bottom: 25px;
        //height: 50px;
        display: block;
        padding-top: 11px;
        cursor: pointer;

      }
    }
  }

  .mainMenu.-open {
    display: block !important;
  }

  .mainMenu > .menu-item-has-children {
    &:hover > a:before {
      opacity: 1;
      visibility: visible;
    }

    a:before {
      position: absolute;
      content: " ";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent $white transparent;
      bottom: -1px;
      left: calc(50% - 8px);
      right: 0;
      visibility: hidden;
      opacity: 0;
      transition: 0.2s opacity;
      transition-delay: 0.2s;
      @media screen and (max-width: $xs-header) { display: none; }
    }

    > a {
      &:after {
        font-size: 10px;
        position: relative;
        //display: inline;
        top: -2px;
        padding-left: 4px;
        line-height: 20px;
        content: '\25be';
      }
      @media screen and (max-width: $xs-header) {
        &:after {
          display: none;
        }
      }

    }
  }

  .search {
    float: right;
    position: relative;
    top: 0;
    @media screen and (max-width: $sm) {
      position: absolute;
      right: 10px;
      top: 50px;
    }
    @media screen and (max-width: $xs-header) { display: none; }

    > input {
      background-color: #215593;
      caret-color: $white;
      padding-left: 15px;
      padding-right: 48px;
      color: $white;
      border:none;
      &:focus {
        outline: none;
        border: none;
      }
    }

    &Input {
      height: 44px;
      width: $searchWidth;
      @media screen and (max-width: $md) { width: $searchWidthMd; }
      @media screen and (max-width: $sm) {
        height: 34px;
      }
      @include input-placeholder() {
        color: #ffffff;
      }
      margin-bottom: 0;
    }
    .icon {
      position: absolute;
      top: 6px;
      right: 14px;
      font-size: 22px;
      color: $white;
      cursor: pointer;
      @media screen and (max-width: $sm) { top: 3px; font-size: 20px; }
    }
  }
}

/* submenu */
.mainMenu > li > ul {
  position: absolute;
  background-color: $white;
  display: block;
  visibility: hidden;
  width: 100%;
  left: 0;
  right: 0;
  top: 100%;
  padding-top: 25px;
  padding-bottom: 60px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  width: 100%;
  z-index: 999;
  opacity: 0;
  transition: opacity 2s linear;

  @media screen and (max-width: $sm) {
    padding-top: 22px;
  }
  .content {
    @media screen and (max-width: $sm) {
      width: 100%;
      //padding-left: 10px;
    }
  }
  .content > li > a {
    font-size: 20px;
    @media screen and (max-width: $sm) { font-size: 16px; }
    font-weight: 600;
    padding: 0;
    color: #000000;
    text-decoration: none;
  }
  .content > li {
    display: block;
    margin-right: 30px;
    @include liReset();
    width: 230px;
    @media screen and (max-width: $sm) {
      width: 220px;
      margin-right: 20px;
    }
    float: left;
    &:last-child {
      margin-right: 0;
    }
  }
}

/* sub sub menu */
.mainMenu > li > ul > .content > li > ul {
  display: block;
  margin-top: 10px;
  li {
    display: block;
    width: 100%;
    @include liReset();
    line-height: 20px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $lightBlue;
      font-size: 15px;
      text-decoration: none;
      font-weight: 400;
      @media screen and (max-width: $sm) { font-size: 14px; line-height: 1.2 }
      &:hover {
        color: $darkBlue;
      }
    }
  }
}

.iconBurger {
  height: 20px;
  display: block;
  float: right;
  position: relative;
  top: 6px;
  display: none;
  padding-right: 10px;
  cursor: pointer;
  @media screen and (max-width: $xs-header) { display: block; }
}

.iconMenu {
  content: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="iso-8859-1"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 73.168 73.168" style="enable-background:new 0 0 73.168 73.168;" xml:space="preserve">
<g>
	<g id="Navigation">
		<g>
			<path d="M4.242,14.425h64.684c2.344,0,4.242-1.933,4.242-4.324c0-2.385-1.898-4.325-4.242-4.325H4.242     C1.898,5.776,0,7.716,0,10.101C0,12.493,1.898,14.425,4.242,14.425z M68.926,32.259H4.242C1.898,32.259,0,34.2,0,36.584     c0,2.393,1.898,4.325,4.242,4.325h64.684c2.344,0,4.242-1.933,4.242-4.325C73.168,34.2,71.27,32.259,68.926,32.259z      M68.926,58.742H4.242C1.898,58.742,0,60.683,0,63.067c0,2.393,1.898,4.325,4.242,4.325h64.684c2.344,0,4.242-1.935,4.242-4.325     C73.168,60.683,71.27,58.742,68.926,58.742z" fill="%23FFFFFF"/>
		</g>
	</g>
</g>
</svg>
  ');
}
.iconCancel {
  content: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="512px" version="1.1" height="512px" viewBox="0 0 64 64" enable-background="new 0 0 64 64">
  <g>
    <path fill="%23FFFFFF" d="M28.941,31.786L0.613,60.114c-0.787,0.787-0.787,2.062,0,2.849c0.393,0.394,0.909,0.59,1.424,0.59   c0.516,0,1.031-0.196,1.424-0.59l28.541-28.541l28.541,28.541c0.394,0.394,0.909,0.59,1.424,0.59c0.515,0,1.031-0.196,1.424-0.59   c0.787-0.787,0.787-2.062,0-2.849L35.064,31.786L63.41,3.438c0.787-0.787,0.787-2.062,0-2.849c-0.787-0.786-2.062-0.786-2.848,0   L32.003,29.15L3.441,0.59c-0.787-0.786-2.061-0.786-2.848,0c-0.787,0.787-0.787,2.062,0,2.849L28.941,31.786z"/>
  </g>
</svg>
  ');
}