@mixin clearFix(){
  &:before,
  &:after{
    content: " ";
    display: table;
  }

  &:after{
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  &{
    *zoom: 1;
  }
}

$lgContent: 1122px;

@mixin doContent() {
  width: 100%;
  max-width: $lgContent;
  margin: 0 auto;
  @media (max-width: $md) {
    max-width: 1000px;
  }
  @media (max-width: $sm) {
    width: 80%;
  }
  @media (max-width: $xs) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@mixin paddingContentReverse() {
  width: 100%;
  padding-left: 10px;
}

@mixin liReset() {
  &:before {
    content: ' ';
    display: none;
  }
}

@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}


