$listSmImgSize: 66px;
.listSm {
  margin-bottom: 20px;
  a {
    text-decoration: none;
  }
  h3 {
    //text-align: left;
  }
  &Stripe {
    padding-top: 14px;
    padding-bottom: 14px;
    display: block;
    border-bottom: 1px solid $borderColor;
    &:first-of-type {
      border-top: 1px solid $borderColor;
    }
    &_img {
      float: left;
      display: block;
      width: $listSmImgSize;
      height: $listSmImgSize;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      background-image: url("../img/placeholder-small.jpg");
    }
    &_img.-rounded {
      border-radius: 50%;
    }
    &_content {
      float: left;
      display: block;
      padding-left: 30px;
      padding-right: 30px;
      width: calc(100% - #{$listSmImgSize});
      .-link {
        text-decoration: underline !important;
      }
    }
    .-date {
      font-size: 14px;
      color: rgba(0,0,0,0.7);
      text-align: left !important;
    }
  }
}

.col-2 {
  width: 50%;
  float: left;
  padding-left: 55px;
  padding-right: 55px;
  @media (max-width: $sm) {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.col-2-no-pad {
  width: 50%;
  float: left;
  @media (max-width: $sm) {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
