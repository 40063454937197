.btn {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  background-color: $lightBlue;
  color: $white;
  padding: 16px 40px 15px 40px;
  text-decoration: none !important;
  transition: 0.2s background-color;
  z-index: 999;
  text-transform: uppercase;
  border-radius: 2px;
  @media (max-width: $sm) {
    font-size: 12px;
    padding: 12px 30px 11px 30px;
  }

  &:hover {
    background-color: $darkBlue;
    color: $white;
  }
}
.btn.-center {
  text-align: center;
}

.btn.-white {
  background-color: #ffffff;
  color: $lightBlue;
}

.btn.-bigMargin {
  margin-top: 50px;
  margin-bottom: 50px;
}

.btnFullWidth {
  width: 100%;
}