$listSmImgSize: 258px;
.listBig {
  padding-left: 55px;
  padding-right: 55px;
  @media (max-width: $sm) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (max-width: $xs) {
    padding-left: 0;
    padding-right: 0;
  }
  h3 {
    text-align: left;
  }
  p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 16px;
    font-weight: 400;
    @media (max-width: $md) {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 14px;
    }
    @media (max-width: $sm) {
      font-size: 12px;
      line-height: 22px;
      margin-bottom: 12px;
    }
  }
  &Stripe {
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid $borderColor;
    .-link {
      font-size: 20px;
      //color: $darkBlue;
      margin-bottom: 10px;
      font-family: FuturaToTDemi, sans-serif;
      text-decoration: underline;
    }
    &:first-of-type {
      border-top: none;
    }
    &_img {
      position: relative;
      float: left;
      display: block;
      width: $listSmImgSize;
      //padding-bottom: 56%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      background-image: url("../img/placeholder-large.jpg");
      @media (max-width: $sm) {
        width: 30%;
      }
      @media (max-width: $xs) {
        width: 20%;
      }
      &:before {
        padding-top: 56.2%;
        content: "";
        display: block;
        max-width: 100%;
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
    &_content {
      float: left;
      display: block;
      padding-left: 30px;
      padding-right: 30px;
      @media (max-width: $sm) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (max-width: $xs) {
        padding-left: 10px;
        padding-right: 10px;
      }
      width: calc(100% - #{$listSmImgSize});
      @media (max-width: $sm) {
        width: 70%;
      }
      @media (max-width: $xs) {
        width: 80%;
      }
    }
    .-date {
      font-size: 14px;
      color: rgba(0,0,0,0.7);
      margin-bottom: 10px;
      font-weight: 300;
      @media (max-width: $md) {
        font-size: 12px;
      }
    }
  }
}

.listSm.col-2 {
  width: 50%;
  float: left;
  padding-left: 55px;
  padding-right: 55px;
}
