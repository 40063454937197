footer {
  height: 260px;
  background-color: $footerColor;
  color: $white;
  display: table;
  width: 100%;
  div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
  }
}