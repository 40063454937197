.videoWrap {
  max-width: 500px;
  display: block;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 40px;
}
.youtubeWrap {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  display: block;
}
.youtubeWrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

section.-youtube {
  font-weight: 300;
}
