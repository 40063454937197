.rozcestnik {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
 // padding-left: 15px;
  //padding-right: 15px;
  margin-bottom: 50px !important;


  &Item {
    width: 354px;
    @media (max-width: $md) { width: 310px;  }
    box-shadow: 0 50px 50px rgba(0,0,0,0.05);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
    background-color: #ffffff;
    text-decoration: none;
    border: 1px solid #f5f5f5;
    border-radius: 2px;
    a, p {
      text-decoration: none;
    }
    padding: 30px 30px 85px 30px;
    @media (max-width: $sm) {
      padding: 20px 20px;
      text-align: left;
    }
    @media (max-width: $xs) {
      padding: 10px 8px;
    }
    p {
      font-size: 20px;
      font-weight: 300;
      @media (max-width: $sm) {
        font-size: 13px;
        margin-bottom: 0;
      }
      @media (max-width: $xs) {
        font-size: 12px;
        line-height: 16px;
      }
    }

  }

  &Button {
    width: 100%;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    .btn {
      background-color: #ffffff;
    }
    @media (max-width: $sm) {
      display: none;
    }
  }

}