.article {
  padding-bottom: 50px;
 .-date {
   font-size: 15px;
   text-align: center;
 }
  &Inner {
    width: 100%;
    padding-left: 96px;
    padding-right: 96px;
    padding-bottom: 40px;
    @media (max-width: $md) {
      padding-left: 85px;
      padding-right: 85px;
    }
    @media (max-width: $sm) {
      padding-left: 0;
      padding-right: 0;
    }

  }
  &_mainImage {
    margin-top: 40px;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  &Footer {
    border-top: 1px solid $borderColor;
    padding-top: 20px;
    .articleSocial {
      margin-top: 20px;
    }
    @media (max-width: $sm) {
      text-align: center;
    }
  }
  &Author {
    font-size: 15px;
    }
  .post-thumbnail {
    margin-top: 20px;
    img {
      width: 100%;
    }
  }
}

.article {
  /*> *:not(.dataTables_wrapper):not(h1):not(.rozcestnik) {*/
  > h2,h3,h4,h5,h6,p, ul, ol, .download-attachments, img, .articleCenter {
    max-width: 738px;
    margin: 0 auto 20px auto;
    @media (max-width: $md) {
      max-width: 660px;
    }
    @media (max-width: $sm) {
      max-width: 580px;
    }
    @media (max-width: $xs) {
    }

  }
  img {
    max-width: 738px;
    display: block; margin-left: auto; margin-right: auto;
    @media (max-width: $md) {
      max-width: 660px;
    }
    @media (max-width: $sm) {
      max-width: 580px;
    }
    @media (max-width: $xs) {
      max-width: 100%;
    }
  }

  img.alignleft, a img.alignleft {
    display: block; margin-left: 0; margin-right: auto;
    @media (max-width: $md) {
      margin-left: 0;
    }
    @media (max-width: $sm) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  img.alignright, a img.alignright {
    display: block; margin-left: auto; margin-right: 0;
    @media (max-width: $md) {
      margin-right: 0;
    }
    @media (max-width: $sm) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  img.aligncenter, a img.aligncenter {
    display: block; margin-left: auto; margin-right: auto;
  }

  blockquote {
    margin: 0 auto;
    max-width: 930px !important;
    @media (max-width: $md) {
      max-width: 830px !important;
    }
    @media (max-width: $sm) {
      max-width: 700px !important;
    }
    @media (max-width: $xs) {
      max-width: 580px !important;
    }

  }
  .accordeons {
    margin: 0 auto 20px auto;
    .accordeon {
      margin-bottom: 0 !important;
    }
    max-width: 798px !important;
    @media (max-width: $md) {
      max-width: 700px !important;
    }
    @media (max-width: $sm) {
    }
    @media (max-width: $xs) {
    }
  }

}

.articleSocial {
  margin-bottom: 40px;
}

.article.-inner {
  padding-left: 96px;
  padding-right: 96px;
  @media (max-width: $md) {
    padding-left: 85px;
    padding-right: 85px;
  }
  @media (max-width: $sm) {
    padding-left: 0;
    padding-right: 0;
  }

}


.iconSocial {
  width: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.iconGoogle {
  content: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="iso-8859-1"?>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 473.939 473.939" style="enable-background:new 0 0 473.939 473.939;" xml:space="preserve">
<circle style="fill:%23CC3731;" cx="236.969" cy="236.967" r="236.967"/>
<path style="fill:%23BC2B2A;" d="M404.527,69.384c92.542,92.542,92.546,242.588-0.004,335.137
	c-92.546,92.542-242.595,92.542-335.137,0L404.527,69.384z"/>
<path style="fill:%23A52728;" d="M471.95,267.16L336.396,131.606l-28.06,28.06l-29.938-29.938l-38.013,6.93l-13.122,5.545
	l-42.379-8.037l-38.043,23.697l-7.296,72.067l59.244,59.244l-36.355,7.76l-34.926,16.838l-9.167,55.85l103.808,103.812
	c4.905,0.299,9.83,0.505,14.806,0.505C357.59,473.935,457.102,383.769,471.95,267.16z"/>
<g>
	<path style="fill:%23FFFFFF;" d="M235.648,141.99c2.193,1.355,4.636,3.33,7.36,5.931c2.615,2.713,5.171,6.05,7.682,9.991
		c2.402,3.734,4.531,8.127,6.391,13.212c1.523,5.078,2.286,11.012,2.286,17.788c-0.198,12.423-2.945,22.357-8.232,29.818
		c-2.574,3.611-5.321,6.941-8.217,9.998c-3.207,3.035-6.619,6.151-10.23,9.31c-2.08,2.144-3.981,4.569-5.744,7.285
		c-2.065,2.821-3.102,6.092-3.102,9.815c0,3.641,1.059,6.623,3.188,8.988c1.792,2.26,3.555,4.239,5.253,5.934l11.783,9.654
		c7.315,6.002,13.736,12.572,19.255,19.805c5.197,7.353,7.899,16.947,8.131,28.797c0,16.827-7.439,31.727-22.301,44.729
		c-15.394,13.448-37.639,20.382-66.694,20.827c-24.325-0.221-42.484-5.407-54.484-15.536c-12.093-9.478-18.159-20.801-18.159-33.99
		c0-6.417,1.961-13.594,5.92-21.482c3.809-7.876,10.728-14.817,20.752-20.793c11.237-6.413,23.053-10.702,35.438-12.853
		c12.258-1.792,22.432-2.818,30.54-3.035c-2.507-3.3-4.741-6.818-6.698-10.559c-2.286-3.637-3.42-8.019-3.42-13.111
		c0-3.065,0.43-5.635,1.302-7.667c0.76-2.17,1.474-4.135,2.118-5.964c-3.944,0.445-7.667,0.681-11.147,0.681
		c-18.492-0.236-32.583-6.05-42.278-17.448c-10.159-10.63-15.233-22.993-15.233-37.1c0-17.059,7.184-32.52,21.571-46.413
		c9.871-8.127,20.127-13.418,30.765-15.925c10.537-2.144,20.411-3.214,29.616-3.214h69.451l-21.444,12.524h-21.422V141.99z
		 M249.055,341.883c0-8.819-2.874-16.486-8.61-23.034c-6.092-6.208-15.596-13.826-28.535-22.874
		c-2.223-0.236-4.819-0.341-7.798-0.341c-1.77-0.217-6.305,0-13.598,0.677c-7.188,1.018-14.548,2.657-22.065,4.917
		c-1.774,0.674-4.251,1.695-7.457,3.046c-3.203,1.471-6.466,3.566-9.789,6.271c-3.207,2.821-5.92,6.32-8.12,10.511
		c-2.556,4.408-3.809,9.714-3.809,15.918c0,12.206,5.512,22.26,16.565,30.17c10.514,7.899,24.879,11.962,43.132,12.198
		c16.363-0.236,28.86-3.843,37.474-10.844C244.853,361.595,249.055,352.738,249.055,341.883z M200.648,240.092
		c9.141-0.337,16.759-3.63,22.847-9.856c2.945-4.412,4.831-8.939,5.669-13.579c0.505-4.64,0.763-8.535,0.763-11.716
		c0-13.68-3.506-27.502-10.507-41.403c-3.289-6.668-7.615-12.112-12.977-16.295c-5.474-3.944-11.768-6.047-18.877-6.275
		c-9.414,0.228-17.227,4.019-23.472,11.379c-5.261,7.689-7.779,16.292-7.543,25.8c0,12.554,3.667,25.613,11.016,39.203
		c3.562,6.335,8.146,11.708,13.788,16.123C186.979,237.892,193.404,240.092,200.648,240.092z"/>
	<polygon style="fill:%23FFFFFF;" points="372.59,167.4 336.157,167.4 336.157,130.948 318.499,130.948 318.499,167.4 282.065,167.4
		282.065,185.05 318.499,185.05 318.499,221.488 336.157,221.488 336.157,185.05 372.59,185.05 	"/>
</g>
</svg>
  ');
}

.iconFacebook {
  content: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="iso-8859-1"?>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 474.294 474.294" style="enable-background:new 0 0 474.294 474.294;" xml:space="preserve">
<circle style="fill:%233A5A98;" cx="237.111" cy="236.966" r="236.966"/>
<path style="fill:%23345387;" d="M404.742,69.754c92.541,92.541,92.545,242.586-0.004,335.134
	c-92.545,92.541-242.593,92.541-335.134,0L404.742,69.754z"/>
<path style="fill:%232E4D72;" d="M472.543,263.656L301.129,92.238l-88.998,88.998l5.302,5.302l-50.671,50.667l41.474,41.474
	l-5.455,5.452l44.901,44.901l-51.764,51.764l88.429,88.429C384.065,449.045,461.037,366.255,472.543,263.656z"/>
<path style="fill:%23FFFFFF;" d="M195.682,148.937c0,7.27,0,39.741,0,39.741h-29.115v48.598h29.115v144.402h59.808V237.276h40.134
	c0,0,3.76-23.307,5.579-48.781c-5.224,0-45.485,0-45.485,0s0-28.276,0-33.231c0-4.962,6.518-11.641,12.965-11.641
	c6.436,0,20.015,0,32.587,0c0-6.623,0-29.481,0-50.592c-16.786,0-35.883,0-44.306,0C194.201,93.028,195.682,141.671,195.682,148.937
	z"/>
</svg>
  ');
}

.iconLinkedIn {
  content: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="iso-8859-1"?>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 473.931 473.931" style="enable-background:new 0 0 473.931 473.931;" xml:space="preserve">
<circle style="fill:%234A86C5;" cx="236.966" cy="236.966" r="236.966"/>
<path style="fill:%233D80B2;" d="M404.518,69.383c92.541,92.549,92.549,242.59,0,335.138c-92.541,92.541-242.593,92.541-335.134,0
	L404.518,69.383z"/>
<path style="fill:%234A86C5;" d="M462.646,309.275c0.868-2.713,1.658-5.456,2.432-8.206
	C464.307,303.823,463.496,306.562,462.646,309.275z"/>
<g>
	<polygon style="fill:%23377CA5;" points="465.097,301.017 465.097,301.017 465.082,301.07 	"/>
	<path style="fill:%23377CA5;" d="M465.097,301.017L336.721,172.641l-29.204,29.204l-20.303-20.303l-16.946,16.946L171.032,99.25
		l-6.155-2.346l-38.08,38.08l45.968,45.964l-44.998,44.995l43.943,43.943l-48.048,48.052L276.475,470.59
		c87.984-14.78,159.5-77.993,186.175-161.311c0.849-2.716,1.658-5.452,2.432-8.206C465.082,301.055,465.09,301.032,465.097,301.017z
		"/>
</g>
<path style="fill:%23FFFFFF;" d="M358.565,230.459v87.883h-50.944v-81.997c0-20.595-7.375-34.656-25.811-34.656
	c-14.084,0-22.458,9.474-26.147,18.634c-1.343,3.278-1.688,7.835-1.688,12.423v85.593H203.02c0,0,0.681-138.875,0-153.259h50.952
	V186.8c-0.094,0.161-0.236,0.34-0.329,0.498h0.329V186.8c6.769-10.425,18.862-25.324,45.923-25.324
	C333.432,161.479,358.565,183.384,358.565,230.459z M149.7,91.198c-17.429,0-28.838,11.439-28.838,26.473
	c0,14.716,11.072,26.495,28.164,26.495h0.344c17.766,0,28.823-11.779,28.823-26.495C177.857,102.636,167.137,91.198,149.7,91.198z
	 M123.886,318.341h50.944V165.083h-50.944V318.341z"/>
</svg>
  ');
}

.iconTwitter {
  content: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="iso-8859-1"?>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 474.006 474.006" style="enable-background:new 0 0 474.006 474.006;" xml:space="preserve">
<circle style="fill:%2313B3CA;" cx="237.003" cy="237.003" r="237.003"/>
<path style="fill:%2310ABB6;" d="M404.567,69.421c92.545,92.541,92.552,242.59-0.004,335.142
	c-92.545,92.549-242.601,92.549-335.142,0.007L404.567,69.421z"/>
<path style="fill:%230EA2A4;" d="M471.821,268.565l-64.048-64.048l-5.28,5.276l-80.684-80.68l-4.183,4.18l-9.684-9.684l-11.831,11.831
	l-9.586-9.583l-15.42,15.416l18.817,18.814l-72.257,72.265l-70.42-70.412l-15.416,15.412l20.295,20.292l-14.282,14.279l23.3,23.3
	l-10.271,10.271l28.385,28.389l-4.632,4.636l26.054,26.058l-30.604,30.608l-22.799-22.795l-22.02,22.02l-27.322-27.326l-5.493,5.497
	l160.787,160.787C365.993,465.726,457.071,379.242,471.821,268.565z"/>
<path style="fill:%23FFFFFF;" d="M370.487,226.792c18.941-1.56,31.783-10.174,36.729-21.856c-6.836,4.198-28.044,8.774-39.756,4.412
	c-0.572-2.746-1.212-5.366-1.841-7.719c-8.924-32.774-39.479-59.168-71.494-55.984c2.589-1.044,5.224-2.021,7.839-2.892
	c3.521-1.265,24.198-4.632,20.946-11.929c-2.746-6.417-28.007,4.853-32.763,6.327c6.279-2.361,16.662-6.425,17.77-13.639
	c-9.62,1.317-19.061,5.867-26.353,12.483c2.63-2.84,4.629-6.294,5.055-10.013c-25.665,16.389-40.654,49.432-52.778,81.488
	c-9.523-9.227-17.964-16.497-25.541-20.531c-21.242-11.397-46.641-23.285-86.513-38.084c-1.224,13.19,6.522,30.735,28.845,42.398
	c-4.838-0.647-13.672,0.801-20.756,2.492c2.885,15.113,12.288,27.562,37.773,33.586c-11.644,0.767-17.661,3.416-23.113,9.126
	c5.295,10.511,18.237,22.881,41.504,20.34c-25.867,11.15-10.541,31.812,10.514,28.729c-35.921,37.103-92.556,34.379-125.076,3.349
	c84.908,115.699,269.485,68.422,296.98-43.019c20.61,0.172,32.725-7.139,40.235-15.199
	C396.817,232.674,379.609,230.593,370.487,226.792z"/>
</svg>
  ');
}

