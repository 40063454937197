$boxesPaddingBottom: 80px;
.project {
  padding-bottom: $boxesPaddingBottom;
  position: relative;
  box-shadow: 0 50px 50px rgba(0,0,0,0.05);
  background: $white;
  text-decoration: none;
  display: block;
  border-radius: 2px;
  width: 33%;
  a, p {
    text-decoration: none;
  }
  &Flex {
    display: flex;
    justify-content: center;

    @media (max-width: $sm) { flex-direction: column; }
  }
  //width: calc(33% - 44px);
  //width: calc(33.33%);
  margin-left: 10px;
  margin-right: 10px;
  max-width: 354px;
  //width: calc(1/3*100% - (1 - 1/3)*18px);
  @media (max-width: $md) { max-width: 320px;  }

  text-align: center;

  @media (max-width: $sm) {
    width: 100%;
    max-width: unset;
    margin-bottom: 30px;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .row {
    @media (max-width: $sm) {
      display: flex;
      flex-direction: row;
    }
  }
  &Header {
    //width: auto;
    @media (max-width: $sm) {
      width: 95px;
    }
    @media (max-width: $xs) {
      width: 70px;
    }
  }
  &Body {
    @media (max-width: $sm) {
      width: calc(100% - 95px);
    }
    @media (max-width: $xs) {
      width: calc(100% - 70px);
    }
  }
  &Bg {
    background-image: url(../img/placeholder-large.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    &:before {
      padding-top: 56.2%;
      content: "";
      display: block;
      max-width: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    @media (max-width: $sm) {
      border-top-left-radius: 2px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 2px;

      width: 95px;
      &:before {
        padding-top: 100%;
      }
    }
    @media (max-width: $xs) {
      width: 70px;
      &:before {
        padding-top: 100%;
      }
    }
  }
  &Text {
    padding: 30px 30px 0 30px;
    @media (max-width: $sm) {
      padding: 20px 20px;
      text-align: left;
    }
    @media (max-width: $xs) {
      padding: 10px 8px;
    }
    p {
      font-size: 20px;
      font-weight: 300;
      @media (max-width: $sm) {
        font-size: 13px;
        margin-bottom: 0;
      }
      @media (max-width: $xs) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  &Button {
    width: 100%;
    position: absolute;
    bottom: 30px;
    @media (max-width: $sm) {
      display: none;
    }
  }
}

.sectionProjects {
  background-color: #f5f5f5;
}